import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: false,
    title: "PRIMA",
    driver_id: 0,
    driver_type: false,
    driver_onesignal: false,
    preTripInspection: false,
    clockIn: false,
    clockOut: false,
    signature: false,
    gotoRoute: -1,
    customer_id: 0,
    route_id: 0,
    msg: {
      show: false,
      color: "red",
      timeout: 5000,
      message: "",
    },
  },
  mutations: {
    logout: function (state) {
      state.token = false;
      state.driver_id = 0;
      state.signature = false;
      state.driver_type = "";
      state.driver_onesignal = "";
    },
    login: function (state, datos) {
      state.token = datos.token;
      state.driver_id = datos.driver_id;
      state.signature = datos.signature;
      state.driver_type = datos.type;
      state.driver_onesignal = datos.driver_onesignal;
    },
    msgMuestra: function (state, vars) {
      state.msg.color = vars.color;
      state.msg.timeout = vars.timeout;
      state.msg.message = vars.message;
      state.msg.show = true;
    },
    msgOculta: function (state) {
      state.msg.color = "red";
      state.msg.timeout = 5000;
      state.msg.message = "";
      state.msg.show = false;
    },
    title: function (state, title) {
      state.title = title;
    },
    preTripInspection: function (state, status) {
      state.preTripInspection = status;
    },
    signature: function (state, status) {
      state.signature = status;
    },
    clockIn: function (state, status) {
      state.clockIn = status;
    },
    clockOut: function (state, status) {
      state.clockOut = status;
    },
    selectRoute: function (state, gotoRoute) {
      state.gotoRoute = gotoRoute;
    },
    customer_id: function (state, customer_id) {
      state.customer_id = customer_id;
    },
    route_id: function (state, route_id) {
      state.route_id = route_id;
    },
  },
  actions: {},
  getters: {
    token: (state) => state.token,
    driver_id: (state) => state.driver_id,
    driver_type: (state) => state.driver_type,
    driver_onesignal: (state) => state.driver_onesignal,
    msg: (state) => state.msg,
    title: (state) => state.title,
    preTripInspection: (state) => state.preTripInspection,
    clockIn: (state) => state.clockIn,
    clockOut: (state) => state.clockOut,
    signature: (state) => state.signature,
    gotoRoute: (state) => state.gotoRoute,
    customer_id: (state) => state.customer_id,
    route_id: (state) => state.route_id,
  },
  modules: {},
});
