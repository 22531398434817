import Vue from "vue";
import App from "./App.vue";
//import './registerServiceWorker'
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import moment from "moment";
import VueLocalStorage from "vue-localstorage";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSignaturePad from "vue-signature-pad";
import VueGeolocation from "vue-browser-geolocation";
import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VueGoogleMaps, {
  load: {
    //key: 'AIzaSyAGKkigHyMJ2-KMRbtuD1YXs0QHVRnrGP0',
    key: "AIzaSyBI6LSnUZLSpwd0VjD2_taxSIcgf0Bdfaw",
    libraries: "places",
  },
});
Vue.use(VueSignaturePad);
Vue.use(VueGeolocation);
Vue.use(VueQrcodeReader);
Vue.config.productionTip = false;

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$http = axios;
if (process.env.NODE_ENV == "development") {
  // Vue.prototype.$api = 'http://prima.test/api/driver/';
  Vue.prototype.$api = "http://prima.test/api/seller/";
} else {
  Vue.prototype.$api = "https://primawm.com/api/seller/";
}

let options = {
  name: "ls",
  storage: "local",
};

Vue.use(VueLocalStorage, options);

// OneSignal
if (process.env.NODE_ENV == "production") {
  window.OneSignal = window.OneSignal || [];
  window.OneSignal.push(() => {
    window.OneSignal.init({
      appId: process.env.VUE_APP_ONESIGNAL,
      allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== "production",
    });
  });
}

new Vue({
  router,
  store,
  vuetify,
  VueLocalStorage,
  moment,
  render: (h) => h(App),
}).$mount("#app");
console.log(process.env.NODE_ENV);
