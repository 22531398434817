<template>
  <v-app id="app">
    <v-snackbar
      v-model="$store.getters.msg.show"
      :color="$store.getters.msg.color"
      multi-line="multi-line"
      v-bind:timeout="$store.getters.msg.timeout"
      top="top"
    >
      {{ $store.getters.msg.message }}
      <v-btn dark text @click="$store.commit('msgOculta')"> Close </v-btn>
    </v-snackbar>
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="$store.getters.token"
      color="#1B6BAA"
    >
      <v-list dense>
        <v-list-item
          link
          v-for="(item, index) in menu"
          :key="index"
          :to="item.action"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="#1B6BAA" dark v-if="$store.getters.token">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $store.getters.title }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon large>
        <v-avatar size="42px" item>
          <v-img src="@/assets/images/icono.png" alt="PRIMA"
        /></v-avatar>
      </v-btn>
    </v-app-bar>

    <v-content :class="{ azul: !$store.getters.token }">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-content>
    <v-footer color="#1B6BAA" app v-if="$store.getters.token">
      <div class="white--text text-right" style="width: 100%">
        &copy; <span v-text="anio"></span> Prima Waste Management
      </div>
    </v-footer>
  </v-app>
</template>
<style scoped>
.azul {
  background: #1b6baa;
}
</style>
<script>
export default {
  name: "App",
  props: {
    source: String,
  },
  data: () => ({
    drawer: null,
    anio: new Date().getFullYear(),
    menu: [
      { icon: "mdi-home", title: "Home", action: "/" },
      { icon: "mdi-directions", title: "Routes", action: "/routes" },
      { icon: "mdi-pen", title: "Signature", action: "/signature" },
      { icon: "mdi-logout", title: "Logout", action: "/logout" },
    ],
  }),
  components: {},
  mounted: function () {
    var token = this.$ls.get("token", false);
    var driver_id = this.$ls.get("driver_id", 0);
    var signature = this.$ls.get("signature", false);
    var driver_type = this.$ls.get("driver_type", "");
    var driver_onesignal = this.$ls.get("driver_onesignal", "");
    if (token && driver_id) {
      var datos = {
        token: token,
        driver_id: driver_id,
        signature: signature,
        type: driver_type,
        driver_onesignal: driver_onesignal,
      };
      this.$store.commit("login", datos);
      if (this.$store.getters.driver_type == "Manager") {
        console.log("Manager");
        this.menu.splice(5, 0, {
          icon: "mdi-swap-horizontal",
          title: "Swap Routes",
          action: "/swap-routes",
        });
      }
    }
  },
};
</script>
